* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    display: block;
    max-width: 100%;
}

button {
    background-color: #2f2f2f;
    margin: 5px;
    padding: 7px 15px;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: .3s;
    border: none;
}

input {
    padding: 5px;
    border-radius: 4px;
    font-size: 16px;
    transition: .3s;
    border: 1px solid #9b9b9b;
    max-width: 100%;
}

body {

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}