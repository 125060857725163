.popupOuter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.popupInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 700px;
    min-height: 500px;
    max-width: 90%;
    /*overflow: auto;*/
}

.textarea_sendMessage_block {
    margin-top: 10px;
    width: 100%;
    /*display: flex;*/
    align-items: center;
    justify-content: space-between;
}

/*.textarea_sendMessage_block > div {*/
/*    width: 48%;*/
/*}*/

.textarea_sendMessage_block h3 {
}

.textarea_sendMessage {
    width: 100%;
    min-height: 200px;
    /*resize: none;*/
    border: 1px solid #b0b0b0;
    border-radius: 4px;
    max-width: 100%;
    padding: 5px;
}

.result_sendMessage {
    width: 100%;
    min-height: 200px;
    border: 1px solid #b0b0b0;
    border-radius: 4px;
    text-align: left;
    padding: 5px;
}

.emoji_sendMessage {
    cursor: pointer;
}

.emojiPicker_sendMessage {
    position: absolute;
    bottom: -200px;
    right: 0;
    z-index: 1000;
}

.emoji_container {
    position: relative;
}

.buttonSuccess {
    background-color: #4caf50;
}

.buttonSuccess:hover {
    background-color: #43a047;
}

.buttonFailure {
    background-color: #f44336;
}

.buttonFailure:hover {
    background-color: #e53935;
}

.greenOnHover:hover {
    background-color: #4caf50;
}

.redOnHover:hover {
    background-color: #f44336;
}

.service_buttons_right {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.service_buttons_left {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.popup_form {
    text-align: left;
    width: 50%;
    margin: 20px auto 0;
}

.popup_form > div {
    margin-bottom: 10px;
}

.popup_form > div > label {
    display: block;
    text-align: left;
    font-weight: 600;
    margin-bottom: 4px;
}

.popup_form > div > input {
    width: 100%;
    padding: 5px;
}

.popup_form > div > textarea {
    width: 100%;
    padding: 5px;
    min-height: 100px;
    max-width: 100%;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
}

.editor {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
}
.editor_body {
    width: 100%;
    height: 100%;
    /*padding: 0 5px;*/
    overflow: auto;
    /*display: flex;*/
}
.editor_body_full_width {
    width: 100%;
    overflow: auto;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
}
.editor_body_full_width > div {
    text-align: left;
    min-height: 150px;
    padding: 0 5px;
    width: 100%;
}
/*2 parts 50%*/
.editor_body > div {
    width: 100%;
    /*min-height: 100px;*/
    /*overflow: auto;*/
    /*word-break: break-all;*/
    /*text-align: left;*/
    /*padding: 10px;*/
}
.editor_body_full_width > div:focus,
.editor_body > div:focus {
    outline: none;
}
.editor_body > div:first-child {
    border-right: 1px solid #9b9b9b;
}

.toolbar {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #9b9b9b;
}

#windowsIco {
    margin-left: 10px;
}

@media screen and (max-width: 768px) {
    .popup_form {
        width: 100%;
    }

    .editor_body > div {
        width: 100%;
    }
    .wmde-markdown-var {
        max-height: 50vh!important;
    }
    .popupInner {
        height: 80vh;
    }
}