.chat_list {
    margin: 0 auto;
    width: 80%;
}

.chat_info {
    gap: 24px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}

.chat_info+.chat_info {
    margin-top: 16px;
}

.chat_title {
    text-align: right;
}

.chat_actions {
    display: flex;
    align-items: center;
    justify-content: start;
}

.chat_actions button {
    margin: 0;
}

.chat_actions button+button {
    margin-left: 12px;
}

@media screen and (max-width: 768px) {
    .chat_list {
        width: 100%;
    }
}