.headerLinks {
    display: flex;
    gap: 30px
}

.headerLinks a {
    text-decoration: none;
    color: #9b9b9b;
    font-size: 16px;
    transition: .3s;
}

.headerLinks a:hover {
    color: #2f2f2f;
    text-decoration: underline;
}

.activeLink {
    color: #2f2f2f !important;
    text-decoration: underline !important;
}

.header {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}