.folders_outer {
    display: flex;
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 10px;
    border: 1px solid #9b9b9b;
    overflow: hidden;

}

.folders_info {
    width: 100%;
}

.folders_list {
    width: 40%;
    overflow-y: auto;
    background-color: #f5f5f5;
    border-right: 1px solid #9b9b9b;
}

.folders_list_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #9b9b9b;
    cursor: pointer;
}

.select_folder_text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.selected_folder {
    background-color: #9b9b9b;
}

@media screen and (max-width: 768px) {
    .folders_outer {
        width: 95%;
    }
}