.contentWrapper {
    margin-top: 12px;

    gap: 48px;
    display: flex;
    align-items: start;
    justify-content: center;
}

.profileForm {
    min-width: 240px;

    gap: 8px;
    display: flex;
    flex-direction: column;
}

.contentWrapper button {
    margin: 0;
}

.contentWrapper button:hover {
    background-color: black;
}

.profileForm button {
    margin-top: 4px;
}