.loginForm {
    margin: 12px auto;

    width: 240px;

    gap: 8px;
    display: flex;
    flex-direction: column;
}

.loginForm button {
    margin: 0;
    margin-top: 4px;
}